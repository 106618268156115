import React, { memo } from 'react';
import ContentCard from '../../contentBasedCard/components/ContentCard';
import ReportContentPopover from '../../cards/components/ReportContentPopover';
import TooltipContainer from '../../components/TooltipContainer';
import LazyImage from '../../../CustomLibraries/LazyImage';
import { Badge } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import useRedirect from '../../../customHooks/useRedirect';
import { routeNames } from '../../../../router/constants';
import { default_images } from '../../../../common/defaultImages';
import useShare from '../../../customHooks/useShare';
import { userDetails } from '../../../../common/common';
import axiosInstance from '../../../../Store/helper/axiosInstance';
import { useQueryClient } from 'react-query';
import { CARD_CLICK } from '../../../tracking/constants';
import { onCardClick } from '../../../tracking/UserActions';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';

const ObservershipCard = (prop) => {
  const {
    type = '',
    slots,
    title,
    start_month,
    duration,
    state_name,
    country_code,
    image,
    specialities_name,
    id,
    hideSlots = false,
    enableCloseListing = false,
    currency,
    is_paid,
    price,
    deeplink,
    mentor_id,
    hideViewDetails = false,
    disableClick = false,
    is_share = true,
    cardType = ''
  } = prop;
  const mentor_user_master_id = userDetails().mentor_user_master_id;
  const share_url = useShare();
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  const cardOptions = {
    flexDirection: type === 'small' ? 'row' : 'column'
  };
  const queryClient = useQueryClient();

  const confirmDeletion = () => {
    axiosInstance
      .post('observership/delete', {
        id
      })
      .then(() => {
        queryClient.invalidateQueries([MY_POSTED_OBSERVERSHIPS]);
        toast.success('Observership deleted successfully.');
      })
      .catch(() => {
        toast.error('Error deleteing Observership.');
      });
  };

  const cardClick = () => {
    trackActivityEvent(CARD_CLICK, prop);
    if (disableClick) {
      return null;
    } else {
      redirectTo(routeNames.observership.observershipDetails, id);
    }
  };

  const isShareable = typeof is_share === 'boolean' ? is_share : is_share == '1' ? true : false;

  return (
    <ContentCard type={cardType} data={prop} className="clr_v3_card--observership">
      {/* {featured && <ContentCard.FeaturedTag></ContentCard.FeaturedTag>} */}

      <ContentCard.Container
        options={{
          paddingTop: { default: '0', md: '0' },
          padding: { default: '0', md: '0' },
          gap: { default: '3', md: '4' },
          flexDirection: 'row'
        }}
      >
        {type === 'small' && (
          <>
            <ContentCard.Image>
              {typeof image === 'string' || image == null ? (
                <LazyImage
                  src={image || default_images.observership}
                  alt="banner"
                  fallback_image={default_images.observership}
                  className={`object-fit-cover w-100 h-100 transition`}
                  extraClass="w-100 h-100 position-relative hover-image"
                />
              ) : (
                <img
                  src={URL.createObjectURL(image)}
                  alt="banner"
                  className={`object-fit-cover w-100 h-100 transition`}
                />
              )}
            </ContentCard.Image>
          </>
        )}

        <ContentCard.Body
          className={`flex-grow-1 ${type === 'small' ? 'ps-0 p-4 pt-2 pt-md-3' : 'p-3 p-md-4'}`}
          options={{ cardOptions, rowGap: { default: '2' } }}
        >
          {type !== 'small' && (
            <>
              {image && (
                <ContentCard.Image onClick={cardClick}>
                  {hideSlots == false && slots && (
                    <Badge className="fs-5 bg-soft-primary fw-medium position-absolute top-0 end-0 me-3 mt-3 z-1">
                      {slots} Seats left
                    </Badge>
                  )}
                  {typeof image === 'string' || image == null ? (
                    <LazyImage
                      src={image}
                      alt="banner"
                      fallback_image={default_images.observership}
                      className={`object-fit-cover w-100 h-100 transition`}
                      extraClass="w-100 h-100 position-relative hover-image"
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="banner"
                      className={`object-fit-cover w-100 h-100 transition`}
                    />
                  )}
                </ContentCard.Image>
              )}
            </>
          )}

          <ContentCard.Header>
            <ContentCard.Tag
              options={{
                iconSize: '5',
                fontSize: '5',
                iconName: `flaticon-focus`,
                color: 'black'
              }}
            >
              Observership
            </ContentCard.Tag>
            {/* <ContentCard.Content>
                  <ContentCard.Title>Dr. Daniel Mitchell</ContentCard.Title>
                </ContentCard.Content> */}
            <ContentCard.Right>
              {type === 'small' && (
                <ContentCard.Tag
                  options={{
                    iconSize: '5',
                    fontSize: '5',
                    iconName: `flaticon-pin`,
                    color: 'black'
                  }}
                >
                  {state_name}, {country_code}
                </ContentCard.Tag>
              )}

              {deeplink && isShareable && (
                <TooltipContainer text="Share">
                  <span
                    className="icon-size-32 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-3 clr_v3_icon-btn-1"
                    onClick={() => {
                      trackActivityEvent(CARD_CLICK, prop);
                      share_url(title, deeplink);
                    }}
                  >
                    <i className="flaticon-share-arrow"></i>
                  </span>
                </TooltipContainer>
              )}

              {/* {mentor_id !== mentor_user_master_id ? (
                <></>
              ) : (
                // <ReportContentPopover type="observership" id={id} />
                <></>
              )} */}
            </ContentCard.Right>
          </ContentCard.Header>

          <div className="d-flex flex-row gap-4 align-items-end" onClick={cardClick}>
            <ContentCard.Content>
              <ContentCard.Title>{title}</ContentCard.Title>
              {specialities_name && (
                <h4 className="fs-5 text-primary fw-normal line-clamp-1">{specialities_name}</h4>
              )}
              <div className="d-flex justify-content-between flex-wrap gap-3">
                <h4 className="fs-5 text-dark fw-normal line-clamp-1">
                  {dayjs(start_month, 'YYYY-MM').format('MMM YY')} -{' '}
                  {dayjs(start_month, 'YYYY-MM').add(duration, 'M').format('MMM YY')}
                </h4>
                {type != 'small' && (
                  <ContentCard.Tag
                    options={{
                      iconSize: '5',
                      fontSize: '5',
                      iconName: `flaticon-pin`,
                      color: 'black'
                    }}
                  >
                    {state_name} ,{country_code}
                  </ContentCard.Tag>
                )}
              </div>
              {type === 'small' && (
                <div className="d-flex align-items-center gap-3 mt-2 flex-row">
                  {!hideSlots && slots && (
                    <Badge bg="soft-primary" className="fs-5 fw-medium">
                      {slots} Seats left
                    </Badge>
                  )}

                  {is_paid == 1 && price && !Number.isNaN(price) ? (
                    <span className="text-primary fw-semibold">
                      Fee {currency === 'INR' ? '₹' : '$'} {price}
                    </span>
                  ) : (
                    <span className="text-primary fw-semibold">Unpaid</span>
                  )}
                </div>
              )}

              {/* {type === 'small' && isMobile && enableCloseListing && (
                <ContentCard.Button
                  className="py-2 ms-auto px-4 fw-medium text-nowrap flex-shrink-0 hover-primary"
                  options={{ variant: 'light' }}
                  onClick={() => redirectTo(routeNames.observership.observershipDetails, id)}
                >
                  Close Listing
                </ContentCard.Button>
              )} */}

              {/* {isMobile && (
                  <span className="fs-5 d-flex align-items-center gap-1 text-black">
                    <i className="flaticon-clock-1"></i> <span>3 hrs ago</span>
                  </span>
                )} */}
            </ContentCard.Content>

            {type === 'small' && !isMobile && (
              <>
                {enableCloseListing ? (
                  <ContentCard.Button
                    className="py-3 px-20 text-nowrap d-none d-md-flex hover-primary"
                    options={{ variant: 'light' }}
                    onClick={confirmDeletion}
                  >
                    Close Listing
                  </ContentCard.Button>
                ) : hideViewDetails ? (
                  <></>
                ) : (
                  <ContentCard.Button
                    className="py-2 text-primary px-4 fw-medium text-nowrap flex-shrink-0"
                    options={{ variant: 'outline-light' }}
                    onClick={cardClick}
                  >
                    View Details
                  </ContentCard.Button>
                )}
              </>
            )}
          </div>
          {type !== 'small' && (
            <>
              <ContentCard.Footer className="mt-2">
                {is_paid == 1 && price && !Number.isNaN(price) ? (
                  <span className="text-primary fw-semibold">
                    Fee {currency === 'INR' ? '₹' : '$'} {price}
                  </span>
                ) : (
                  <span className="text-primary fw-semibold">Unpaid</span>
                )}

                {enableCloseListing ? (
                  <ContentCard.Button
                    className="py-2 ms-auto px-4 fw-medium text-nowrap flex-shrink-0 hover-primary"
                    options={{ variant: 'light' }}
                    onClick={cardClick}
                  >
                    Close Listing
                  </ContentCard.Button>
                ) : (
                  <ContentCard.Button
                    className="py-2 ms-auto px-4 fw-medium text-nowrap flex-shrink-0"
                    options={{ variant: 'primary' }}
                    onClick={cardClick}
                  >
                    Apply Now
                  </ContentCard.Button>
                )}
              </ContentCard.Footer>
            </>
          )}
        </ContentCard.Body>
      </ContentCard.Container>
    </ContentCard>
  );
};

export default memo(ObservershipCard);
