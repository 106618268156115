import React, { memo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetApplicationDetails,
  useGetObservershipApplicants,
  useGetObservershipDetails
} from '../../../Queries/QueryHooks/observership.hook';
import SideBarCTA from '../../../components/baseUiV3/components/SideBarCTA';
import MainContainer from '../../../components/baseUiV3/template/pageContainer/MainContainer';
import LeftSideBar from '../../../layout/uiv3/defaultLayout/components/LeftSideBar';
import RightSideBar from '../../../layout/uiv3/defaultLayout/components/RightSideBar';
import ProfileCard from '../../ddi/components/ProfileCard';
import CompleteYourProfile from './CTA/CompleteYourProfile';
import DoctorsAchievement from './CTA/DoctorsAchievement';
import MentorAvailability from './CTA/MentorAvailability';
import ShareNow from './CTA/ShareNow';
import UpcomingAppointments from './CTA/UpcomingAppointments';
import ObservershipRequestCard from './components/ObservershipRequestCard';
import ObservershipCard from '../../../components/baseUiV3/contentCards/observership/ObservershipCard';
import Divider from '../../../components/baseUiV3/components/Divider';
import { useProfileAll } from '../../../Queries/QueryHooks/profile.hook';
import { Button, Modal } from 'react-bootstrap';
import axiosInstance from '../../../Store/helper/axiosInstance';
import { toast } from 'react-toastify';
import useRedirect from '../../../components/customHooks/useRedirect';
import { pageTitles } from '../../../common/pageTitles';
import { generateUrl, openNewTab, parseJson } from '../../../common/common';
import { Seo } from '../../../components/seo/Seo';
import { BannerItem, BannerWorker } from '../../../components/banner';
// import SponsoredCard from '../../../components/banner/SponsoredCard';
import { TrackerComponent } from '../../../TrackerFolder';
import ApplicationDetailsSkeleton from './components/skeleton/ApplicationDetailsSkeleton';
import ObservershipCardSkeleton from './components/skeleton/ObservershipCardSkeleton';
import ObservershipRequestCardSkeleton from './components/skeleton/ObservershipRequestCardSkeleton';
import { ImFilePdf, IoDocumentTextSharp } from '../../../components/icons';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import { isMobile } from 'react-device-detect';
import { routeNames } from '../../../router/constants';
import SponsorCard from '../../../components/banner/SponsorCard';
import { PAGE_VIEW } from '../../../components/tracking/constants';

const pageName = pageTitles.Observership.applicationDetails;
function ApplicationDetails() {
  const { id, title } = useParams();

  const onSuccess = (res) => {
    if (res === null || res?.id == null) {
      redirectTo('Unavailable');
    }
  };

  const onSuccessRequestDetails = (res) => {
    if (res === null || res?.observership_id == null) {
      redirectTo('Unavailable');
    }
  };

  const { data: requestDetails, isLoading: requestDetailsLoader } = useGetApplicationDetails(
    id,
    onSuccessRequestDetails,
    onSuccessRequestDetails
  );
  const { redirectTo } = useRedirect();
  const url = generateUrl();
  console.log('requestDetails', requestDetails);

  const { data: observershipDetails, isLoading: observershipDetailsLoader } =
    useGetObservershipDetails(requestDetails?.observership_id, onSuccess, onSuccess);
  // const observershipDetailsLoader = true;
  console.log('observershipDetails', observershipDetails);

  const { achievement, education, membership, registration, status, application_details } =
    requestDetails || {
      achievement: [],
      education: [],
      membership: [],
      registration: []
    };

  const { certificate, submited_answer } = application_details
    ? JSON.parse(application_details)
    : {};
  const certificateList = certificate ? parseJson(certificate, []) : [];
  const submitedAnswerList = submited_answer ? parseJson(submited_answer, []) : [];

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [type, setType] = useState(null);
  console.log('certificateList', submitedAnswerList);

  function acceptApplication() {
    axiosInstance
      .post('observership/approved', {
        application_id: id
      })
      .then(() => {
        toast.success('Applicant accepted Successfully.');
        setShowConsentModal(false);
      })
      .catch(() => {
        toast.success('Error accepting applicant.');
        setShowConsentModal(false);
      });
  }

  function rejectApplication() {
    axiosInstance
      .post('observership/reject', {
        application_id: id
      })
      .then(() => {
        toast.success('Applicant rejected Successfully.');
        setShowConsentModal(false);
      })
      .catch(() => {
        toast.success('Error rejecting applicant.');
        setShowConsentModal(false);
      });
  }

  function showAcceptModal() {
    setShowConsentModal(true);
    setType('accept');
  }

  function showRejectModal() {
    setShowConsentModal(true);
    setType('reject');
  }

  function handleCancel() {
    redirectTo(routeNames.observership.applicantsListing, requestDetails?.observership_id);
  }

  return (
    <>
      <TrackerComponent
        page_name={pageName}
        page_id={id}
        module_page="observership_applicationDetails"
        type={PAGE_VIEW}
      />
      <Seo title={`CLIRNET - ${pageName}`} url={url} />
      <LeftSideBar>
        <ProfileCard />
        <MentorAvailability />
        <SideBarCTA />
      </LeftSideBar>
      <MainContainer>
        {/* <BannerWorker page_name={'observership'} page_id={id} /> */}
        <div className="w-100">
          <BannerItem position={'top'} page_name={'observership'} layout="speciality" />
        </div>
        <div className="w-100 d-flex flex-column row-gap-3 row-gap-sm-4">
          {!observershipDetailsLoader && observershipDetails && (
            <ObservershipCard {...observershipDetails} cardType="small" />
          )}
          {observershipDetailsLoader ? <ObservershipCardSkeleton /> : <></>}
          {requestDetailsLoader ? (
            <></>
          ) : (
            <h4 className="fs-3 text-black fw-semibold mt-2">
              Observership <span className="text-primary">Requested</span>
            </h4>
          )}

          <Divider />
          {!requestDetailsLoader && requestDetails && (
            <div className="d-flex flex-column row-gap-3 row-gap-sm-4">
              <ObservershipRequestCard
                key={'requestcard' + id}
                {...requestDetails}
                hideDetailsBtn
                disableClick
              />
            </div>
          )}
          {requestDetailsLoader ? <ObservershipRequestCardSkeleton /> : <></>}
          <div className="p-4 bg-white rounded">
            {/* loop */}
            <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
              <div className="clr_v3__timeline  position-absolute h-100 start-0">
                {education?.length > 0 && (
                  <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>
                )}
                <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
              </div>
              {education?.length > 0 ? (
                <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">Education</h2>
              ) : (
                <></>
              )}
              {education?.length > 0 ? (
                <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                  {education?.map((_edu) => (
                    <div
                      className="position-relative clr_v3_userProfile__about__2ndTimeline"
                      key={_edu.education_id}
                    >
                      <h4 className="fs-16 text-black mb-2 fw-semibold">
                        {_edu.degree} <br />
                        <span>
                          {_edu.start_year} - {_edu.end_year}
                        </span>
                      </h4>
                      <h5 className="fs-4 text-black mb-2 fw-medium">{_edu.college}</h5>
                      <p>{_edu.description ? _edu.description : '---'}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <ApplicationDetailsSkeleton />
              )}
            </div>
            {/* loop END*/}
            {/* loop */}
            <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
              <div className="clr_v3__timeline  position-absolute h-100 start-0">
                {membership?.length > 0 && (
                  <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>
                )}
                <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
              </div>
              {membership?.length > 0 && (
                <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">
                  Affiliation & Membership
                </h2>
              )}
              {membership?.length > 0 ? (
                <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                  {membership?.map((_mem) => (
                    <div
                      className="position-relative clr_v3_userProfile__about__2ndTimeline"
                      key={_mem.membership_id}
                    >
                      <h4 className="fs-16 text-black mb-2 fw-semibold">{_mem.institute_name}</h4>
                      <h5 className="fs-4 text-black mb-2 fw-medium">
                        {_mem.start_year} - {_mem.end_year}
                      </h5>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {/* loop END*/}
            {/* loop */}
            <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
              <div className="clr_v3__timeline  position-absolute h-100 start-0">
                {achievement?.length > 0 && (
                  <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>
                )}
                <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
              </div>
              {achievement?.length > 0 && (
                <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">
                  Contribution / Achievements
                </h2>
              )}

              {achievement?.length > 0 ? (
                <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                  {achievement?.map((_ach) => (
                    <div
                      className="position-relative clr_v3_userProfile__about__2ndTimeline"
                      key={_ach.achv_id}
                    >
                      <h4 className="fs-16 text-black mb-2 fw-semibold">{_ach.institute}</h4>
                      <h5 className="fs-4 text-black mb-2 fw-medium">{_ach.year}</h5>
                      <p>{_ach.description ? _ach.description : '---'}</p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {/* loop END*/}
            {/* loop */}
            <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
              <div className="clr_v3__timeline  position-absolute h-100 start-0">
                {registration?.length > 0 && (
                  <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>
                )}
                <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
              </div>
              {registration?.length > 0 && (
                <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">Registrations</h2>
              )}
              <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                {registration?.length > 0 ? (
                  <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                    {registration?.map((_reg) => (
                      <div
                        className="position-relative clr_v3_userProfile__about__2ndTimeline"
                        key={_reg.registration_id}
                      >
                        <h4 className="fs-16 text-black mb-2 fw-semibold">
                          {' '}
                          {_reg.institute_name}
                        </h4>
                        <h5 className="fs-4 text-black mb-2 fw-medium">{_reg.year}</h5>
                        <p> {_reg.registration_no}</p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            {/* loop END*/}
            {/* loop */}
            {certificateList && Array.isArray(certificateList) && certificateList?.length > 0 && (
              <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
                <div className="clr_v3__timeline  position-absolute h-100 start-0">
                  <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>

                  <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                  <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
                </div>
                {certificateList &&
                  Array.isArray(certificateList) &&
                  certificateList?.length > 0 && (
                    <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">
                      Attachments
                    </h2>
                  )}
                {/* {console.log('certificateList', JSON.parse(applicationDetails))} */}
                {certificateList &&
                Array.isArray(certificateList) &&
                certificateList?.length > 0 ? (
                  <div className="w-100 d-flex gap-4 gap-sm-5">
                    {certificateList &&
                      Array.isArray(certificateList) &&
                      certificateList?.map((_cert, index) => (
                        <div
                          className="position-relative bg-light p-3 rounded d-flex flex-column  gap-2"
                          key={index}
                          onClick={() => openNewTab(_cert.file_url)}
                        >
                          <ReturnIcon fileUrl={_cert.file_url} />
                          <p className="line-clamp-1">{_cert.file_name}</p>
                        </div>
                      ))}
                  </div>
                ) : null}
              </div>
            )}
            {/* loop END*/}

            {/* loop */}
            {submitedAnswerList &&
              Array.isArray(submitedAnswerList) &&
              submitedAnswerList?.length > 0 && (
                <div className="px-3 ps-sm-4 pe-sm-0 pb-5 position-relative clr_v3_userProfile__about__row clr_v3__timelineParrent">
                  <div className="clr_v3__timeline  position-absolute h-100 start-0">
                    <span className="rounded-circle border border-1 border-primary translate-middle-x start-50 top-0 z-1 position-absolute"></span>
                    <span className="h-100 w-100 bg-primary start-0 top-0 position-absolute"></span>
                    <span className="end bottom-0 start-0 bg-primary position-absolute"></span>
                  </div>
                  <h2 className="mb-4 text-start lh-1 text-black fw-semibold fs-2">
                    Other Questions
                  </h2>
                  <div className="w-100 d-flex flex-column row-gap-4 row-gap-sm-5">
                    {submitedAnswerList.map((_question, index) => {
                      if (_question && 'question' in _question) {
                        return (
                          <div
                            className="position-relative clr_v3_userProfile__about__2ndTimeline"
                            key={index}
                          >
                            <h4 className="fs-16 text-black mb-2 fw-semibold">
                              {' '}
                              {_question?.question}
                            </h4>
                            <h5 className="fs-4 text-black mb-2 fw-medium">{_question?.answer}</h5>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </div>
              )}
            {/* loop END*/}
            {status == '2' && (
              <div className="d-flex gap-3 justify-content-end mt-4">
                <Button
                  className={`${isMobile ? 'flex-grow-1' : ''}`}
                  variant="outline-primary"
                  size="sm"
                  onClick={handleCancel}
                  aria-label="Cancel"
                >
                  Cancel
                </Button>
                <Button
                  className={`${isMobile ? 'flex-grow-1' : ''}`}
                  variant="danger"
                  size="sm"
                  onClick={showRejectModal}
                  aria-label="Cancel"
                >
                  Reject
                </Button>
                <Button
                  className={`${isMobile ? 'flex-grow-1' : ''}`}
                  variant="primary"
                  size="sm"
                  onClick={showAcceptModal}
                  aria-label="Cancel"
                >
                  Accept Application
                </Button>
              </div>
            )}
          </div>
        </div>
        <Modal
          className="centerModal"
          size="sm"
          centered
          show={showConsentModal}
          onHide={() => setShowConsentModal(false)}
        >
          <Modal.Header className="position-relative border-0 p-4 mb-1">
            <Modal.Title className="fs-4 text-black fw-semibold">Please Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <form>
              <div className="w-100 centerModalFrm">
                <h1 className="fs-16 text-black fw-semibold mb-4">
                  Are you sure to {type === 'accept' ? 'accept' : 'reject'} ?
                </h1>
                <div className="d-flex flex-wrap centerModalBttns">
                  <button
                    className="col fs-4 btn btn-danger py-3 px-4"
                    onClick={() => setShowConsentModal(false)}
                    aria-label="Cancel"
                  >
                    Cancel
                  </button>
                  <button
                    className="col fs-4 btn btn-primary py-3 px-4"
                    type="button"
                    onClick={() => {
                      type === 'accept' ? acceptApplication() : rejectApplication();
                    }}
                    aria-label="Submit"
                  >
                    Yes I'm Sure
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </MainContainer>
      <RightSideBar>
        <BannerItem
          position="side-top"
          page_name="observership"
          wrapper={(props) => <SponsorCard {...props} />}
          layout="speciality"
        />
        <UpcomingAppointments cardType={'appointment'} />
        {/* Complete your Profile */}
        <CompleteYourProfile />
        <DoctorsAchievement />
        <ShareNow />
        <BannerItem
          position="side-bottom"
          page_name="observership"
          wrapper={(props) => <SponsorCard {...props} />}
          layout="speciality"
        />
      </RightSideBar>
    </>
  );
}

const ReturnIcon = ({ file, fileUrl }) => {
  const type = fileUrl?.split('.')[1];
  switch (type) {
    case 'pdf':
      return <ImFilePdf customClass={'icon-size-32 text-danger'} />;
    case 'jpg':
    case 'jpeg':
    case 'png':
      // return <BsImage customClass={'icon-size-32 text-primary'} />;
      return (
        <>
          <LazyImage
            src={fileUrl}
            alt=""
            className={`object-fit-cover w-auto h-auto mw-100 mh-100 rounded-2`}
            extraClass="hover-image icon-size-48 position-relative d-flex align-items-center justify-content-center"
          />
        </>
      );
    default:
      return <IoDocumentTextSharp customClass={'icon-size-32 text-secondary'} />;
  }
};

export default memo(ApplicationDetails);
