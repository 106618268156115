import React, { memo } from 'react';
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap';
import { useProfileCompletion } from '../../../../Queries/QueryHooks/profile.hook';
import useRedirect from '../../../../components/customHooks/useRedirect';
import { routeNames } from '../../../../router/constants';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';
import { CARD_VIEW, CARD_CLICK } from '../../../../components/tracking/constants';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';

function CompleteYourProfile({ data }) {
  const { data: completion } = useProfileCompletion();
  const { trackActivityEvent } = useTracker();

  const { redirectTo } = useRedirect();
  if (!completion || Math.round(completion) >= 100) return null;
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      className="rounded-3 bg-extra-light p-3 p-sm-4 d-flex flex-column observership-card-1 gap-2"
      onClick={() => {
        trackActivityEvent(CARD_CLICK, data);
        redirectTo(routeNames.userProfile.landing);
      }}
      style={{ cursor: 'pointer' }}
    >
      <h2 className="fs-16 text-black fw-semibold mb-2">Complete your Profile</h2>
      <div className="d-flex d-flex align-items-center gap-3 mb-1">
        <h4 className="flex-grow-1 fw-normal text-black fs-4">
          Complete your profile for tailored opportunities in healthcare.
        </h4>

        <div className="icon-size-50 rounded-circle border border-2 border-soft-primary flex-shrink-0 d-flex align-items-center justify-content-center">
          <svg
            className="observership-card-1__icon icon-size-24"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 15C19.5899 15 22.5 12.0899 22.5 8.5C22.5 4.91015 19.5899 2 16 2C12.4101 2 9.5 4.91015 9.5 8.5C9.5 12.0899 12.4101 15 16 15Z"
              fill-opacity="0.5"
            />
            <path d="M27.3795 28.8999C26.8095 29.5999 25.9595 29.9999 25.0595 29.9999H6.93948C6.03948 29.9999 5.18948 29.5999 4.61948 28.8999C4.04948 28.1999 3.81948 27.2899 3.99948 26.4099L4.03948 26.2299C4.79948 22.4199 5.59948 18.4699 8.60948 15.7699C9.53948 14.9399 10.9395 14.8199 12.0895 15.4699C14.4495 16.8199 17.5495 16.8199 19.9095 15.4699C21.0595 14.8199 22.4595 14.9399 23.3895 15.7699C26.3995 18.4699 27.1995 22.4199 27.9595 26.2299L27.9995 26.4099C28.1795 27.2899 27.9495 28.1999 27.3795 28.8999Z" />
          </svg>
        </div>
      </div>

      <div className="d-flex gap-4 align-items-center">
        <BootstrapProgressBar
          variant="primary"
          now={completion ? Math.round(completion) : 0}
          className="observership-card-1__progress flex-grow-1"
        />
        {/* <ProgressBar now={30} /> */}
        <span className="text-primary fw-semibold">{completion ? Math.round(completion) : 0}%</span>
      </div>
    </TrackerWrapper>
  );
}

export default memo(CompleteYourProfile);
