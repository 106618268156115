import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import LazyImage from '../../../components/CustomLibraries/LazyImage';
import useRedirect from '../../../components/customHooks/useRedirect';
import { Button } from 'react-bootstrap';
import { routeNames } from '../../../router/constants';
import { DEMO_USER_PICTURE } from '../../../common/common';
import TrackerWrapper from '../../../components/tracking/TrackerWrapper';
import { CARD_VIEW, SUBMIT_CLICK } from '../../../components/tracking/constants';
import { useTracker } from '../../../TrackerFolder/TrackerComponent';

const ProfileCard = () => {
  const userDetails = useSelector((state) => state.common.user_details);
  const { redirectTo } = useRedirect();
  const { trackActivityEvent } = useTracker();

  const onEditProfileClick = () => {
    trackActivityEvent(SUBMIT_CLICK, userDetails);
    redirectTo('profile');
  };

  const onGetProfileClick = () => {
    redirectTo(routeNames.subscription.pricing, 'profile');
  };

  const parseSpecialities = (jsonStr) => {
    const jsonObj = JSON.parse(jsonStr);
    return jsonObj?.map((obj) => obj.specialities_name).join(', ');
  };

  const menu = JSON.parse(userDetails?.menu);

  return (
    <TrackerWrapper
      data={userDetails}
      type={CARD_VIEW}
      className="clr_v3_SpecialityLeft__profileContent d-flex justify-content-center flex-column p-4 rounded-3 border-light bg-extra-light border gap-4"
    >
      <div className="d-flex flex-column align-items-center gap-3">
        <div className="rounded-circle p-2 border border-2 border-primary position-relative">
          {/* {menu?.premium && (
            <i className="flaticon-premium fs-16 clr_v3_premium__tag__icon position-absolute top-0 end-0 mt-n2 me-n2"></i>
          )} */}
          <div className="overflow-hidden icon-size-60 icon-size-sm-80 rounded-circle position-relative">
            {userDetails && userDetails?.profile_image && (
              <LazyImage
                src={userDetails?.profile_image}
                width=""
                height=""
                alt="Dr. User Image"
                className="object-fit-cover w-100 h-100 float-start"
                fallback_image={DEMO_USER_PICTURE}
              />
            )}
          </div>
        </div>

        <div className="text-center">
          {(userDetails?.first_name || userDetails?.last_name || userDetails?.middle_name) && (
            <h4
              className="fs-16 fw-semibold mb-1 text-black cursorPointer"
              onClick={onEditProfileClick}
            >{`${userDetails?.first_name || ''} ${userDetails?.middle_name || ''} ${
              userDetails?.last_name || ''
            }`}</h4>
          )}
          {userDetails?.speciality && (
            <p className="fs-4">{parseSpecialities(userDetails?.speciality)}</p>
          )}
        </div>
      </div>

      <div className="d-flex gap-3 flex-wrap">
        <Button
          variant="outline-primary"
          onClick={onEditProfileClick}
          className="rounded-pill py-3 gap-2 d-flex align-items-center justify-content-center flex-grow-1"
          id="profile-edit-btn"
        >
          Edit Profile <i className="flaticon-pen"></i>
        </Button>
        {/* {!menu?.premium && (
          <Button
            variant="primary"
            onClick={onGetProfileClick}
            className="rounded-pill py-3 gap-2 d-flex align-items-center justify-content-center flex-grow-1"
            id="profile-edit-btn"
          >
            <i className="flaticon-premium"></i> Get Premium
          </Button>
        )} */}
      </div>
    </TrackerWrapper>
  );
};

export default memo(ProfileCard);
