import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form, Modal, OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../../../../components/centerModal/css/centerModal.scss';
import axiosInstance from '../../../../Store/helper/axiosInstance';
import { useLocation } from 'react-router-dom';
import { SUBMIT_CLICK } from '../../../tracking/constants';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';

const ReportContentPopover = ({ type, id, onReportSubmit, isDoctorVoiceCard = false, data }) => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userFeedback, setUserFeedback] = useState('');
  const [userSelectType, setUserSelectType] = useState('');
  const { pathname } = useLocation();
  const handleShow = () => setShow(!show);
  const handleHide = () => setShow(false);
  const modalOpen = () => setModalShow(true);
  const modalClose = () => setModalShow(false);
  const { trackActivityEvent } = useTracker();

  const isDisabled =
    pathname == '/' ||
    pathname?.toLowerCase()?.includes('login') ||
    pathname?.toLowerCase()?.includes('registration') ||
    pathname?.toLowerCase()?.includes('forget-password') ||
    pathname?.toLowerCase()?.includes('reset-password') ||
    pathname?.toLowerCase()?.includes('share');

  const submitUserFeedback = () => {
    trackActivityEvent(SUBMIT_CLICK, data);
    if (userSelectType && userFeedback) {
      axiosInstance
        .post('speciality/report_content', {
          content_type: type,
          content_id: id,
          type: userSelectType,
          user_message: userFeedback
        })
        .then((res) => {
          toast.success(res.data.message);
          modalClose();
          setUserFeedback('');
          setUserSelectType('');
          if (onReportSubmit) {
            onReportSubmit();
          }
        })
        .catch((e) => {
          console.log('Error', e);
        });
    } else {
      if (userFeedback == '') {
        toast.error(
          'Hey there! We want to make sure our service is working perfectly for you. If you have any issues or feedback, please let us know. Thank you!'
        );
      }
    }
  };

  const popoverTop = (
    <Popover
      id="popover-positioned-top"
      // data-bs-placement="bottom"
      // data-bs-trigger="focus"
      title="Report Content"
      className="shadow report-content-popover"
    >
      <PopoverBody>
        <ul className="list-unstyled m-0 d-flex flex-column gap-2 fs-5">
          <li>
            <a
              className="p-3 rounded bg-light d-flex align-items-center jusstify-content-start gap-2"
              href="javascript:void(0)"
              onClick={() => {
                trackActivityEvent(SUBMIT_CLICK, data);
                setUserSelectType('not_interest');
                handleHide();
                modalOpen();
              }}
            >
              <i className="flaticon-sad fs-16"></i>
              Not Interested
            </a>
          </li>
          <li>
            <a
              className="p-3 rounded bg-light d-flex align-items-center jusstify-content-start gap-2"
              href="javascript:void(0)"
              onClick={(e) => {
                trackActivityEvent(SUBMIT_CLICK, data);
                setUserSelectType('report');
                handleHide();
                modalOpen();
              }}
            >
              <i className="flaticon-traffic-signal fs-16"></i>
              Report
            </a>
          </li>
          {/* <li>
            <a
              className="p-3 rounded bg-light d-flex align-items-center jusstify-content-start gap-2"
              href="javascript:void(0)"
              onClick={(e) => {
                setUserSelectType('save');
                handleHide();
                modalOpen();
              }}
            >
              <i className="flaticon-save-instagram fs-16"></i>
              Save
            </a>
          </li> */}
        </ul>
      </PopoverBody>
    </Popover>
  );
  if (isDisabled) {
    return null;
  }
  return (
    <>
      <OverlayTrigger
        data-bs-toggle="popover"
        overlay={popoverTop}
        trigger="click"
        rootClose
        placement="bottom"
      >
        <span
          className="icon-size-32 clr_v3_card__icon transition cursorPointer flex-shrink-0 d-flex align-items-center justify-content-center rounded-pill rippleEffect position-relative fs-3 clr_v3_icon-btn-1"
          onClick={handleShow}
        >
          <i className={`flaticon-more-1`}></i>
        </span>
      </OverlayTrigger>
      <Modal className="centerModal" show={modalShow} size="md" centered>
        <Modal.Header>
          <Modal.Title className="fs-4 text-black fw-semibold">
            {userSelectType == 'not_interest'
              ? 'Please tell why you are not interested '
              : 'Please tell the reason '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="w-100 float-start centerModalFrm">
              <FloatingLabel
                className="floatingFrmRow mb-3"
                controlId="floatingInput"
                label="Report Your issue"
              >
                <Form.Control
                  className="fs-4"
                  type="text"
                  placeholder="We value your feedback! Please let us know about any issues you encounter while using our product, and include any relevant details."
                  value={userFeedback}
                  onChange={(e) => setUserFeedback(e.target.value)}
                />
              </FloatingLabel>
              <div className="floatingFrmRow d-flex flex-wrap centerModalBttns">
                <button
                  className={`col fs-4 btn btn-danger`}
                  type="button"
                  onClick={modalClose}
                  aria-label="Cancel"
                >
                  Cancel
                </button>
                <button
                  className={`col fs-4 btn btn-primary`}
                  type="button"
                  onClick={submitUserFeedback}
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportContentPopover;
