import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import useShare from '../../../../components/customHooks/useShare';
import { useSelector } from 'react-redux';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';
import { SHARE_CLICK } from '../../../../components/tracking/constants';

function ShareNow({ deeplink = '', data }) {
  const { trackActivityEvent } = useTracker();

  const userDetails = useSelector((state) => state.common.user_details);
  const name = `${userDetails?.first_name} ${userDetails?.last_name}`;
  const share_url = useShare();
  if (!deeplink && !userDetails?.mentor_deeplink) {
    return null;
  }

  return (
    <div
      className="rounded-3 bg-extra-light p-3 p-sm-4 d-flex flex-column observership-card-1 gap-2 text-center align-items-center"
      onClick={() => {
        trackActivityEvent(SHARE_CLICK, data);
        share_url(name, deeplink || userDetails?.mentor_deeplink);
      }}
    >
      <p>Grow together—share with your doctor friends.</p>
      <Button className=" py-3">Share Now</Button>
    </div>
  );
}

export default memo(ShareNow);
