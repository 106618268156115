import { useDispatch, useSelector } from 'react-redux';
import {
  getLocalStorage,
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage
} from '../../common/common';
import { useOpenPixel } from './useOpenPixel';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { recordOpixEvent } from '../../Store/actions/tracking.action';
import { useParams } from 'react-router-dom';
import { CARD_VIEW } from '../tracking/constants';
import { routeNames } from '../../router/constants';

export const useTrackUserActivity = () => {
  const dispatch = useDispatch();
  const openPixelInitialize = useOpenPixel();
  useEffect(() => {
    openPixelInitialize(
      window,
      document,
      window['opix'],
      'opix',
      `${process.env.REACT_APP_OPIX_REPORTING_ENDPOINT}`,
      1
    );
  }, []);

  const current_menu = useSelector((state) => state.common.current_menu);
  const { id } = useParams();

  function generateURL(currentLocation, type_id) {
    var url = 'https://' + window.location.hostname + '/#';

    if (currentLocation) {
      if (currentLocation.includes('medwiki')) {
        url = url + routeNames.medwiki.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('clinical')) {
        url = url + routeNames.clinicalVideo.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('surveys')) {
        url = url + routeNames.spq.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('sessions')) {
        url = url + routeNames.sessions.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('channel')) {
        url = url + routeNames.channel.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('grandround')) {
        url = url + routeNames.grandRound.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('course')) {
        url = url + routeNames.course.cover + '/' + type_id;
      } else if (
        currentLocation.toLowerCase().includes('epaper') ||
        currentLocation.toLowerCase().includes('epub')
      ) {
        url = url + routeNames.epaper.detail + '/' + type_id;
      } else if (currentLocation.toLowerCase().includes('observership')) {
        url = url + routeNames.observership.observershipDetails + '/' + type_id;
      }
    }
    return url;
  }
  function userActivityTracker(inView, item, typeOfItem, pageId = 0) {
    var {
      type_id,
      type,
      survey_id,
      sponsor_id,
      session_id,
      sponsor_name,
      event_type,
      specialities_ids_and_names,
      question,
      title,
      session_title,
      id: content_id
    } = item || {};

    if (inView) {
      const hasSponsor =
        specialities_ids_and_names &&
        Array.isArray(specialities_ids_and_names) &&
        specialities_ids_and_names.length > 0
          ? true
          : false;

      //SEND GA EVENT FOR CARD
      if (event_type === CARD_VIEW && hasSponsor) {
        let currentLocation = '';
        let pageTitle = '';
        pageTitle = document.title;
        currentLocation = window.location.href;
        ReactGA.send({
          hitType: 'pageview',
          page: generateURL(currentLocation, type_id),
          title: question ? question : title ? title : session_title ? session_title : '',
          location: generateURL(currentLocation, type_id)
        });
      }
      console.groupCollapsed('userActivityTracker');
      console.log(item);
      console.groupEnd('userActivityTracker');

      const payload = {
        page: current_menu,
        pageId: pageId ? pageId : id ? id : 0,
        content_type: type,
        content_id: type_id || session_id || survey_id || content_id,
        sponsor_id: sponsor_name,
        event_type: event_type
      };
      // opix('event', `${type}_${typeOfItem}`, payload);

      var previousAnalytics = JSON.parse(getSessionStorage('analytics_sent', 'null'));
      if (previousAnalytics && previousAnalytics.length > 0) {
        var findIndex = previousAnalytics.indexOf(payload);
        if (findIndex === -1) {
          previousAnalytics.push(payload);
          setSessionStorage('analytics_sent', JSON.stringify(previousAnalytics));
          dispatch(recordOpixEvent(type, payload));
        }
      } else {
        setSessionStorage('analytics_sent', JSON.stringify([payload]));
        dispatch(recordOpixEvent(type, payload));

        // opix('event', `${type}_${typeOfItem}`, payload);
      }

      // if (JSON.parse(getSessionStorage('analytics_sent'))?.length > 4) {
      //   opix('event', ``, getSessionStorage('analytics_sent'));

      //   removeSessionStorage('analytics_sent');
      // }
    }
  }

  return {
    userActivityTracker
  };
};
