export const routeNames = {
  dashboard: {
    landing: '/dashboard'
  },

  medwiki: {
    landing: '/medwiki',
    detail: '/medwiki/detail',
    listing: '/medwiki/all',
    topRated: '/medwiki/top-rated',
    mostCommented: '/medwiki/most-commented',
    popular: '/medwiki/popular',
    mostRead: '/medwiki/most-read'
  },

  sessions: {
    landing: '/sessions',
    detail: '/sessions/detail',
    listing: '/sessions/all',
    live: '/sessions/live',
    upcoming: '/sessions/upcoming',
    previous: '/sessions/previous',
    speaker: '/speaker/profile',
    photoBooth: '/sessions/photobooth',
    queryListing: '/query/all',
    waitingRoom: '/sessions/waitingroom'
  },

  clinicalVideo: {
    landing: '/clinical-video',
    detail: '/clinical-video/detail',
    listing: '/clinical-video/all'
  },

  grandRound: {
    landing: '/gr',
    detail: '/gr/detail',
    listing: '/gr/all'
  },

  course: {
    landing: '/course',
    listing: '/course/all',
    content: '/course/content',
    summary: '/course/summary',
    cover: '/course/cover'
  },

  spq: {
    landing: '/spq/landing',
    detail: '/spq/detail',
    listing: '/spq/all',
    listingPoll: '/spq/all/poll',
    points: '/spq/points',
    question: '/spq/questions',
    review: '/spq/review',
    result: '/spq/result',
    attempt: '/spq/attempted',
    poll: '/poll/detail'
  },

  differentialDiagnosis: {
    landing: '/ddx'
  },
  ddi: {
    landing: '/ddi'
  },
  openddx: {
    landing: '/openddx'
  },
  aida: {
    landing: '/aida'
  },
  epaper: {
    landing: '/epaper',
    detail: '/epaper/detail'
    // listing: "epaper/all",
  },

  telemed: {
    landing: '/telemed'
  },

  discussRefer: {
    landing: '/discuss-refer',
    discussForm: '/discuss/form',
    referForm: '/refer/form',
    referSubmit: '/refer/submit',
    referReinitiate: '/refer/reinitiate',
    caseDetail: '/case/detail'
  },

  doctube: {
    landing: '/doctube',
    listing: '/doctube/videos',
    dashboard: '/doctube/dashboard',
    analytics: '/doctube/analytics',
    profile: '/doctube/profile',
    upload: '/doctube/upload',
    playlist: '/doctube/playlist/detail',
    playlistManage: '/doctube/playlist/manage',
    profileVideo: '/profile/video'
  },

  channel: {
    landing: '/channel',
    manage: '/channel/followed',
    suggestion: '/channel/suggestion',
    detail: '/channel/detail'
  },

  userProfile: {
    landing: '/profile'
  },

  hospitalProfile: {
    landing: '/hospital/profile'
  },

  speciality: {
    landing: '/specialities',
    speciality: '/speciality',
    activities: '/activities',
    vault: '/vault',
    listing: '/specialities/all'
  },

  demo: {
    landing: '/demo'
  },

  election: {
    landing: '/election',
    candidateSelection: '/election/candidates',
    voting: '/election/voting'
  },

  verification: {
    mobile: '/verification/mobile'
  }, 

  ads: {
    landing: '/ads'
  },

  notification: {
    landing: '/notification'
  },

  helpAndSupport: {
    landing: '/help-and-support'
  },

  search: {
    landing: '/search',
    tag: '/search/tag',
    listing: '/search/all'
  },

  certificate: {
    landing: '/certificates',
    detail: '/certificate/detail',
    upload: '/certiicate/upload'
  },

  cpdReview: {
    landing: '/cpd/review'
  },

  subscription: {
    pricing: '/subscription'
  },
  observership: {
    landing: '/observership',
    registration: '/observership/registration',
    profile: '/observership/profile',
    postOpportunity: '/observership/post-opportunity',
    observershipDetails: '/observership/details',
    observershpChat: '/observership/chat',
    observershipDetailsPreview: '/observership/preview/details',
    applyForObserverShip: '/observership/apply',
    applicantsListing: '/observership/applications',
    applicationDetails: '/observership/application/details'
  },
  share: {
    gr: '/share/gr',
    training: '/share/training',
    ddx: '/share/differential_diagnosis',
    ddi: '/share/ddi',
    openddx: '/share/openddx',
    medwiki: '/share/medwiki',
    poll: '/share/Poll',
    quiz: '/share/survey',
    session: '/share/session',
    doctorVoice: '/share/doctorVoice',
    channel: '/share/channel',
    epub: '/share/epub',
    election: '/share/election/:election_id',
    dnr: 'share/Dnr',
    doctube: '/share/doctube',
    doctube_fst: '/share/doctube_fst',
    profile: '/share/Profile',
    clinicalVideo: '/share/archived_video',
    observership: '/share/observership',
    mentorship: '/share/mentor',
    claimMentorship: '/share/claim_mentorship/'
  }
};
