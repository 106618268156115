import React, { useEffect, useState, useRef, memo } from 'react';
import SpecialityProgressBar from '../../../speciality/SpecialityProgressBar';
import {
  DEMO_USER_PICTURE,
  getSessionStorage,
  handleCompressedUpload,
  isFileAllowed,
  setSessionStorage
} from '../../../../common/common';
import { useDispatch, useSelector } from 'react-redux';
import LazyImage from '../../../../components/CustomLibraries/LazyImage';
import {
  useGetUserCmePointsAchived,
  useGetUserGoal,
  useGetUserGoalAchived
} from '../../../../Queries/QueryHooks/user.hook';
import useRedirect from '../../../../components/customHooks/useRedirect';
import LeftSideLoader from '../../../../skeleton/uiv3/dashboard/LeftSideLoader';
import { getFcmToken, getUserDetails, renderHeaderOnce } from '../../../../Store/actions';
import { postProfileImage } from '../../../../Store/actions/profile.action';
import { toast } from 'react-toastify';
import BackdropComponent from '../../../../components/BackdropComponent';
import { firebaseUpload } from '../../../../common/firebase';
import packageJson from '../../../../../package.json';
import { Button } from 'react-bootstrap';
import { routeNames } from '../../../../router/constants';
import { useTracker } from '../../../../TrackerFolder/TrackerComponent';
import { LIKE_CLICK } from '../../../../components/tracking/constants';

const LeftSideContent = ({ page = '' }) => {
  const userRegionState = useSelector((state) => state.share.UserRegion);
  const inputRef = useRef();
  const userDetails = useSelector((state) => state.common.user_details);
  const { trackActivityEvent } = useTracker();
  // console.log('userGoal', userGoal);
  const [backdrop, setBackdrop] = useState(false);
  const [progress, setProgress] = useState(0);
  const [firebaseToken, setFirebaseToken] = useState('');
  useEffect(() => {
    dispatch(getFcmToken(setFirebaseToken));
  }, []);
  const { redirectTo } = useRedirect();
  const onSuccess = (data) => {
    console.log('goal on success', data);
  };
  const onError = () => {};
  const onGetAchivedSuccess = (data) => {
    console.log('goal on success 1', data);
  };
  const onSuccessCmePoints = (data) => {
    console.log('goal on success cme point', data);
  };

  const dispatch = useDispatch();
  const [points, setPoints] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [achivedUserGoal, setAchivedUserGoal] = useState({
    article_read: {
      total_goal: 0,
      content_type: 'medwiki'
    },
    video_watched: {
      total_goal: 0,
      content_type: 'archived_video'
    },
    attended_cme: {
      total_goal: 0,
      content_type: 'session'
    }
  });
  const [userGoal, setuserGoal] = useState({
    read: 0,
    watch: 0,
    discuss: 0,
    cme_point: 0
  });
  const { data: goalData, isLoading: goalLoader } = useGetUserGoal();
  const { data: achivedGoalData, isLoading: achivedGoalLoader } = useGetUserGoalAchived();
  const { data: cmePoints, isLoading: cmePointsLoader } = useGetUserCmePointsAchived();

  function nextMultipleOfTen(num) {
    return Math.ceil(num / 10) * 10;
  }

  useEffect(() => {
    console.log('temptest -1', achivedGoalData);
    if (goalData || achivedGoalData) {
      console.group('goalData');
      const { user_goal = '' } = goalData ?? {};
      console.log('goal_on', user_goal);
      let temp_obj = user_goal ? JSON.parse(user_goal) : {};
      if (getSessionStorage('user_goal', false) || getSessionStorage('goal_achieved', false)) {
        let obj_from_storage = JSON.parse(getSessionStorage('user_goal', '{}'));
        let achived_from_storage = JSON.parse(getSessionStorage('goal_achieved', '{}'));
        console.log('achived_from_storage', achived_from_storage, achivedGoalData);
        const {
          article_read = 0,
          attended_cme = 0,
          video_watched = 0
        } = achived_from_storage || {};
        console.log(
          'obj_from_storage',
          obj_from_storage,
          article_read,
          attended_cme,
          video_watched
        );
        console.groupEnd('goalData');
        setuserGoal((prev) => {
          let temp = { ...prev };
          temp.read = Math.max(
            temp_obj?.read,
            nextMultipleOfTen(parseInt(article_read?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.article_read?.total_goal))
          );
          temp.watch = Math.max(
            temp_obj?.watch,
            nextMultipleOfTen(parseInt(video_watched?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.video_watched?.total_goal))
          );
          temp.discuss = Math.max(
            temp_obj?.discuss,
            nextMultipleOfTen(parseInt(attended_cme?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.attended_cme?.total_goal))
          );
          temp.cme_point =
            cmePoints > temp_obj?.cme_point
              ? nextMultipleOfTen(parseInt(cmePoints))
              : nextMultipleOfTen(parseInt(temp_obj?.cme_point));

          console.log('temptest', temp_obj, achived_from_storage, temp, achivedUserGoal);
          return temp;
        });
      } else if (user_goal) {
        console.log('user_goal', user_goal, achivedGoalData);
        setuserGoal(JSON.parse(user_goal));
        setSessionStorage('user_goal', user_goal);
      } else {
        setuserGoal({
          read: 0,
          watch: 0,
          discuss: 0,
          cme_point: 0
        });
      }
    }
    return () => {};
  }, [goalData, cmePoints, achivedGoalData]);

  useEffect(() => {
    console.group('achivedGoalData');
    if (achivedGoalData) {
      const { goal_achieved } = achivedGoalData;
      console.log('goal on 2', goal_achieved);
      if (getSessionStorage('goal_achieved', false)) {
        let object_from_storage = JSON.parse(getSessionStorage('goal_achieved', '{}'));
        console.log('object_from_storage', object_from_storage);
        if (
          object_from_storage &&
          typeof object_from_storage == 'object' &&
          Object.keys(object_from_storage).length > 0
        ) {
          console.log('goal_achieved 1', object_from_storage);

          setAchivedUserGoal((prev) => {
            console.log('prev', prev);
            // return { ...prev };
            let temp = { ...prev };
            temp.article_read.total_goal = Math.max(
              parseInt(temp.article_read?.total_goal ?? 0),
              parseInt(object_from_storage?.article_read?.total_goal ?? 0)
            );
            temp.video_watched.total_goal = Math.max(
              parseInt(temp.video_watched?.total_goal ?? 0),
              parseInt(object_from_storage?.video_watched?.total_goal ?? 0)
            );
            temp.attended_cme.total_goal = Math.max(
              parseInt(temp.attended_cme?.total_goal ?? 0),
              parseInt(object_from_storage?.attended_cme?.total_goal ?? 0)
            );
            console.log('temp', temp, object_from_storage);
            return temp;
          });
        } else {
          console.log('goal_achieved 2', goal_achieved);
        }
      } else {
        console.log('goal_achieved 3', goal_achieved);
        setAchivedUserGoal(goal_achieved);
        setSessionStorage('goal_achieved', JSON.stringify(goal_achieved));
      }
    }
    console.groupEnd('achivedGoalData');
    return () => {};
  }, [achivedGoalData]);

  const parseSpecialities = (jsonStr) => {
    const jsonObj = JSON.parse(jsonStr);
    return jsonObj.map((obj) => obj.specialities_name).join(', ');
  };
  const onHelpAndSupportClick = () => {
    redirectTo(routeNames.helpAndSupport.landing);
  };

  const onEditProfileClick = () => {
    trackActivityEvent(LIKE_CLICK, userDetails);
    redirectTo(routeNames.userProfile.landing);
  };
  const onPrivacyClick = () => {};
  const uploadProfileImage = (e) => {
    if (!e) return;
    if (isFileAllowed(e)) {
      handleCompressedUpload(e, (compressedFile) => {
        setBackdrop(true);
        firebaseUpload(
          compressedFile,
          firebaseToken,
          ['jpg', 'jpeg', 'png'],
          (_p) => setProgress(_p),
          (url, fileName) => {
            dispatch(
              postProfileImage({ image: url }, (res) => {
                if (res != 'error') {
                  dispatch(getUserDetails(() => {}));
                  setBackdrop(false);
                  // dispatch(renderHeaderOnce());
                  toast.success('Profile picture updated successfully');
                  setProgress(0);
                }
              })
            );
          },
          () => {
            setBackdrop(false);
            setProgress(0);
          }
        );
      });
    } else {
      toast.error('Maximum allowed file size : 2 MB');
    }
  };
  const onGetProfileClick = () => {
    redirectTo(routeNames.subscription.pricing, 'profile');
  };
  const menu = JSON.parse(userDetails?.menu);
  return (
    <>
      {!goalLoader && !achivedGoalLoader ? (
        <div className="w-100 clr_v3_SpecialityLeft d-flex flex-column justify-content-between align-item-center position-relative">
          <div className="clr_v3_SpecialityLeft__profileContent d-flex justify-content-center flex-column p-4 rounded-3 border-light border gap-4">
            <div className="d-flex flex-column align-items-center gap-3">
              <div className="rounded-circle p-2 border border-2 border-primary position-relative">
                {/* {menu?.premium && (
                  <i className="flaticon-premium fs-16 clr_v3_premium__tag__icon position-absolute top-0 end-0 mt-n2 me-n2"></i>
                )} */}
                <div className="overflow-hidden icon-size-60 icon-size-sm-80 rounded-circle position-relative">
                  {userDetails && userDetails?.profile_image && (
                    <LazyImage
                      src={userDetails?.profile_image}
                      width=""
                      height=""
                      alt="Dr. User Image"
                      fallback_image={DEMO_USER_PICTURE}
                      className="object-fit-cover w-100 h-100 float-start"
                    />
                  )}
                  {/* <input
                  type="file"
                  className="position-absolute start-0 top-0 w-100 h-100 opacity-0 z-1"
                  onChange={(e) => uploadProfileImage(e.target.files[0])}
                /> */}
                </div>
                <div className="position-absolute end-0 bottom-0 btn btn-primary rounded-circle d-flex align-items-center justify-content-center z-2 p-0 icon-size-32 overflow-hidden cursorPointer">
                  <input
                    type="file"
                    className="position-absolute start-0 top-0 w-100 h-100 opacity-0 z-n1"
                    onChange={(e) => uploadProfileImage(e.target.files[0])}
                    ref={inputRef}
                  />
                  <i
                    className="flaticon-camera fs-3 text-white"
                    onClick={() => inputRef.current.click()}
                  ></i>
                </div>
              </div>

              <div className="text-center">
                {(userDetails?.first_name ||
                  userDetails?.last_name ||
                  userDetails?.middle_name) && (
                  <h4
                    className="fs-16 fw-semibold mb-1 text-black cursorPointer"
                    onClick={onEditProfileClick}
                  >{`${userDetails?.first_name} ${userDetails?.middle_name} ${userDetails?.last_name}`}</h4>
                )}
                {userDetails?.speciality && (
                  <p className="fs-4">{parseSpecialities(userDetails?.speciality)}</p>
                )}
              </div>
            </div>
            {!goalLoader && !achivedGoalLoader && userGoal && achivedUserGoal && (
              <div className="d-flex flex-column justify-content-center gap-3">
                {/* {userGoal?.read && ( */}
                <SpecialityProgressBar
                  title="Article Read"
                  rating={`${achivedUserGoal?.article_read?.total_goal || 0}/${
                    userGoal?.read && !isNaN(userGoal?.read) ? userGoal?.read : 10
                  }`}
                />
                {/* )} */}
                <SpecialityProgressBar
                  title="Videos Watched"
                  rating={`${achivedUserGoal?.video_watched?.total_goal || 0}/${
                    userGoal?.watch && !isNaN(userGoal?.watch) ? userGoal?.watch : 10
                  }`}
                />
                <SpecialityProgressBar
                  title="Attended CME"
                  rating={`${achivedUserGoal?.attended_cme?.total_goal || 0}/${
                    userGoal?.discuss ? userGoal?.discuss : 10
                  }`}
                />
                <SpecialityProgressBar
                  title="CME Points"
                  rating={`${cmePoints || 0}/${
                    userGoal?.cme_point && !isNaN(userGoal?.cme_point) ? userGoal?.cme_point : 10
                  }`}
                />
              </div>
            )}
            <div className="d-flex gap-3 flex-wrap">
              {page != 'profile' && (
                <Button
                  variant="outline-primary"
                  onClick={onEditProfileClick}
                  className="rounded-pill py-3 gap-2 d-flex align-items-center justify-content-center flex-grow-1"
                  id="profile-edit-btn"
                >
                  Edit Profile <i className="flaticon-pen"></i>
                </Button>
              )}
              {/* {!menu?.premium && (
                <Button
                  variant="primary"
                  onClick={onGetProfileClick}
                  className="rounded-pill py-3 gap-2 d-flex align-items-center justify-content-center flex-grow-1"
                  id="profile-edit-btn"
                >
                  <i className="flaticon-premium"></i> Get Premium
                </Button>
              )} */}
            </div>
          </div>

          <div className="clr_v3_SpecialityLeft__footer d-flex flex-column gap-5 mt-50">
            <ul className="clr_v3_SpecialityLeft__footer__list d-flex flex-column gap-2 align-items-start list-unstyled">
              <li>
                <a className="text-black" href="javascript:void(0)" onClick={onHelpAndSupportClick}>
                  Help & Support
                </a>
              </li>
              <li>
                <a
                  className="text-black"
                  target="_blank"
                  href={
                    userRegionState === 'GT' || userRegionState === 'GL'
                      ? process.env.REACT_APP_TERMS_CONDITIONS_LINK_GT
                      : process.env.REACT_APP_TERMS_CONDITIONS_LINK
                  }
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  className="text-black"
                  target="_blank"
                  href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
            <div className="d-flex flex-row gap-2">
              <a
                href={process.env.REACT_APP_FACEBOOK_LINK}
                target="_blank"
                className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
              >
                <i className="flaticon-facebook-app-symbol fs-4"></i>
              </a>
              <a
                href={process.env.REACT_APP_TWITTER_LINK}
                target="_blank"
                className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
              >
                <i className="flaticon-twitter fs-4"></i>
              </a>
              <a
                href={process.env.REACT_APP_INSTAGRAM_LINK}
                target="_blank"
                className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
              >
                <i className="flaticon-instagram fs-4"></i>
              </a>
              <a
                href={process.env.REACT_APP_LINKEDIN_LINK}
                target="_blank"
                className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
              >
                <i className="flaticon-linkedin fs-4"></i>
              </a>
              <a
                href={process.env.REACT_APP_YOUTUBE_LINK}
                target="_blank"
                className="clr_v3_SpecialityLeft__footer__social bg-light p-3 d-flex justify-content-center align-items-center rounded-2"
              >
                <i className="flaticon-youtube fs-4"></i>
              </a>
            </div>
          </div>
          <h4 className="fs-16 text-black fw-bold  mt-5">CLIRNET v-{packageJson.version}</h4>
        </div>
      ) : (
        <LeftSideLoader />
      )}
      <BackdropComponent backdrop={backdrop} progress={progress} type={'progress'} />
    </>
  );
};

export default memo(LeftSideContent);
