import React from 'react';
import InView from 'react-intersection-observer';
import { useTrackUserActivity } from '../customHooks/useTrackUserActivity';

function TrackerWrapper(props) {
  const { children, data, type } = props;
  const { userActivityTracker } = useTrackUserActivity();

  return (
    <InView
      as="div"
      onChange={(inView) => {
        userActivityTracker(inView, { event_type: type, ...data }, 'card');
      }}
      {...props}
    >
      {children}
    </InView>
  );
}

export default TrackerWrapper;
