import dayjs from 'dayjs';
import React, { memo } from 'react';
import TrackerWrapper from '../../../../components/tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../../components/tracking/constants';

function MentorAvailability({ from, to, data }) {
  if (!from || !to) {
    return null;
  }
  return (
    <TrackerWrapper
      type={CARD_VIEW}
      data={data}
      className="rounded-3 bg-extra-light p-3 p-sm-4 d-flex flex-column observership-card-1 gap-2 "
    >
      <h2 className="fs-16 text-black fw-semibold mb-2">
        Mentor is <span className="text-primary">available</span> at
      </h2>
      <div className="d-flex p-3 border border-soft-primary rounded d-flex align-items-center gap-3 mb-1">
        <h4 className="flex-grow-1 text-primary">
          {dayjs(from).format('dddd')}, {dayjs(from).format('MMMM DD, YYYY')}{' '}
          <span className="d-block">
            {dayjs(from).format('hh:mm A')} - {dayjs(to).format('hh:mm A')}
          </span>
        </h4>
        <svg
          className="observership-card-1__icon icon-size-36 flex-shrink-0"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_817_16385)">
            <path
              d="M31.4581 8.4677C30.779 7.54851 30.0025 6.69642 29.1504 5.9349L29.8168 5.18896C30.7198 5.99605 31.5428 6.89911 32.2627 7.87322L31.4581 8.4677Z"
              fillOpacity={0.8}
            />
            <path
              d="M18.0641 35.9996C18.0632 35.9999 18.0624 35.9999 18.0607 35.9996C18.0607 35.9996 18.0604 35.9996 18.0601 35.9996C8.33091 35.9976 0.416016 28.0813 0.416016 18.3516C0.416016 15.5139 1.1059 12.6932 2.41038 10.1943L3.29701 10.6575C2.06643 13.0136 1.41645 15.6744 1.41645 18.3516C1.41645 27.5307 8.8835 34.9983 18.0624 34.9995C27.2413 34.9983 34.7083 27.5307 34.7083 18.3516C34.7083 15.6744 34.0583 13.0139 32.828 10.6575L33.7144 10.1943C35.0191 12.6934 35.7085 15.5139 35.7085 18.3516C35.7085 28.0813 27.7939 35.9976 18.0646 35.9996C18.0644 35.9996 18.0644 35.9996 18.0641 35.9996Z"
              fillOpacity={0.8}
            />
            <path
              d="M4.66782 8.4677L3.86328 7.87322C4.58289 6.89967 5.40555 5.99662 6.30917 5.18896L6.97556 5.93462C6.1229 6.6967 5.34667 7.5488 4.66782 8.4677Z"
              fillOpacity={0.8}
            />
            <path
              d="M20.0503 14.2998H12.7505C11.7877 14.2998 11 15.0876 11 16.0504V21.9497C11 22.9125 11.7877 23.7002 12.7505 23.7002H20.0503C21.0131 23.7002 21.8009 22.9125 21.8009 21.9497V16.0504C21.8009 15.07 21.0131 14.2998 20.0503 14.2998Z"
              fillOpacity={0.8}
            />
            <path
              d="M25.7042 15.2626C25.5992 15.2801 25.4942 15.3326 25.4066 15.3851L22.6758 16.9606V21.0219L25.4241 22.5974C25.9318 22.895 26.562 22.7199 26.8596 22.2122C26.9471 22.0547 26.9996 21.8796 26.9996 21.6871V16.2779C26.9996 15.6302 26.3869 15.105 25.7042 15.2626Z"
              fillOpacity={0.8}
            />
            <path d="M19.1746 17.0339C20.7532 17.0339 22.0329 15.7542 22.0329 14.1756C22.0329 12.5971 20.7532 11.3174 19.1746 11.3174C17.5961 11.3174 16.3164 12.5971 16.3164 14.1756C16.3164 15.7542 17.5961 17.0339 19.1746 17.0339Z" />
            <path
              d="M19.1758 11C18.5477 11 17.9337 11.1863 17.4114 11.5352C16.8892 11.8842 16.4821 12.3802 16.2417 12.9605C16.0014 13.5408 15.9385 14.1793 16.061 14.7954C16.1836 15.4114 16.486 15.9773 16.9302 16.4215C17.3743 16.8656 17.9402 17.1681 18.5562 17.2906C19.1723 17.4132 19.8109 17.3503 20.3912 17.1099C20.9715 16.8695 21.4675 16.4625 21.8164 15.9402C22.1654 15.418 22.3516 14.8039 22.3516 14.1758C22.3507 13.3338 22.0157 12.5266 21.4204 11.9313C20.825 11.3359 20.0178 11.001 19.1758 11ZM19.1758 16.7165C18.6733 16.7165 18.1821 16.5675 17.7643 16.2883C17.3465 16.0091 17.0209 15.6123 16.8286 15.1481C16.6363 14.6838 16.586 14.173 16.684 13.6802C16.782 13.1873 17.024 12.7346 17.3793 12.3793C17.7346 12.024 18.1873 11.782 18.6802 11.684C19.173 11.586 19.6838 11.6363 20.1481 11.8286C20.6123 12.0209 21.0091 12.3465 21.2883 12.7643C21.5675 13.1821 21.7165 13.6733 21.7165 14.1758C21.7158 14.8494 21.4479 15.4952 20.9716 15.9716C20.4952 16.4479 19.8494 16.7158 19.1758 16.7165Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_817_16385">
              <rect width="36" height="36" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </TrackerWrapper>
  );
}

export default memo(MentorAvailability);
