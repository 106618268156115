import React, { forwardRef, memo } from 'react';
import {
  cardWrapperDefaulOptions,
  featuredTagDefaultOptions,
  cardContainerDefaultOptions,
  cardHeaderDefaultOptions,
  cardBodyDefaultOptions,
  headerTagDefaultOptions,
  headerRightDefaultOptions,
  bodyContentDefaultOptions,
  cardImageDefaultOptions,
  contentTitleDefaultOptions,
  contentDescriptionDefaultOptions,
  cardFooterDefaultOptions,
  footerLeftActionBarDefaultOptions,
  footerLeftIconDefaultOptions,
  sponserDefaultOptions,
  // sponsorLogoDefault,
  buttonDefaultOptions,
  buttonContainerDefault,
  premiumTagDefaultOptions
} from './constants/CardContainer';
import SponsorLogoSwiper from '../../../../pages/shareLogin/Components/SponsorLogoSwiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImage from '../../../CustomLibraries/LazyImage';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import TrackerWrapper from '../../../tracking/TrackerWrapper';
import { CARD_VIEW } from '../../../tracking/constants';

const ContentCard = forwardRef(
  (
    {
      className,
      children,
      options = cardWrapperDefaulOptions,
      type,
      featured,
      onClick,
      isLocked,
      data
    },
    ref
  ) => {
    const designOptions = { ...cardWrapperDefaulOptions, ...options };
    return (
      <TrackerWrapper
        data={data}
        type={CARD_VIEW}
        onClick={onClick}
        ref={ref}
        className={`${type == 'small' ? 'clr_v3_card--small' : ''} ${featured ? 'clr_v3_card--featured' : ''
          } ${isLocked ? 'clr_v3_card--premium' : ''}
       clr_v3_card position-relative overflow-hidden d-${designOptions?.display} gap-${designOptions?.gap?.default
          } gap-md-${designOptions?.gap?.md}
    bg-${designOptions?.bgColor} rounded-${designOptions?.rounded} flex-${designOptions?.flexDirection
          } ${className ? className : ''}`}
      >
        {children}
      </TrackerWrapper>
    );
  }
);

ContentCard.FeaturedTag = ({ className, children, options = featuredTagDefaultOptions }) => {
  const designOptions = { ...featuredTagDefaultOptions, ...options };
  return (
    <span
      className={`clr_v3_card--featured__tag z-3 bg-${designOptions?.bgColor
        } rounded-pill position-absolute text-${designOptions?.color} icon-size-${designOptions?.iconSize
        } ${className ? className : ''}`}
    >
      <i
        className={`clr_v3_card--featured__icon translate-middle start-50 top-50 position-absolute flaticon-${designOptions?.iconName}
      fs-${designOptions?.iconSize}`}
      ></i>
    </span>
  );
};

ContentCard.PremiumTag = ({ className, children, options = premiumTagDefaultOptions }) => {
  const designOptions = { ...premiumTagDefaultOptions, ...options };
  return (
    <span
      className={`clr_v3_premium__tag position-absolute shineEffect z-3 text-${designOptions?.color
        } icon-size-${designOptions?.iconSize.default} icon-size-lg-${designOptions?.iconSize.lg} ${className ? className : ''
        }`}
    >
      <i
        className={`clr_v3_premium__tag__icon translate-middle start-50 top-50 position-absolute flaticon-premium
       ${isMobile ? `fs-${designOptions?.fontSize.default}` : `fs-${designOptions?.fontSize.lg}`
          } text-${designOptions?.color}`}
      ></i>
    </span>
  );
};

ContentCard.Container = ({ className, children, options = cardContainerDefaultOptions }) => {
  const designOptions = { ...cardContainerDefaultOptions, ...options };
  return (
    <div
      className={`
      clr_v3_card__container flex-grow-1 w-100
      p-${designOptions?.padding?.default}
      p-md-${designOptions?.padding?.md}
      pt-${designOptions?.paddingTop?.default}
      pt-md-${designOptions?.paddingTop?.md}
      d-${designOptions?.display}
      flex-${designOptions?.flexDirection}
      gap-${designOptions?.gap?.default} 
      gap-md-${designOptions?.gap?.md}
      ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Header = ({ className, children, options = cardHeaderDefaultOptions }) => {
  const designOptions = { ...cardHeaderDefaultOptions, ...options };
  return (
    <div
      className={`
      clr_v3_card__header
      align-items-center justify-content-between
      d-${designOptions?.display}
      flex-${designOptions?.flexDirection}
      gap-${designOptions?.gap?.default} 
    ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Tag = ({ className, children, options = headerTagDefaultOptions }) => {
  const designOptions = { ...headerTagDefaultOptions, ...options };
  return (
    <div
      className={`d-flex align-items-center clr_v3_card__typeTag gap-${designOptions?.gap?.default
        } ${className ? className : ''}`}
    >
      <i
        className={`
  ${designOptions.iconName}
  fs-${designOptions.iconSize}
  text-${designOptions.iconColor}
  `}
      ></i>
      <span
        className={`line-clamp-1
  text-${designOptions.color}
  fs-${designOptions.fontSize}`}
      >
        {children}
      </span>
    </div>
  );
};

ContentCard.Right = ({ className, children, options = headerRightDefaultOptions }) => {
  const designOptions = { ...headerRightDefaultOptions, ...options };
  return (
    <div
      className={`align-items-center d-flex
  gap-${designOptions?.flex?.gap?.default} 
  gap-md-${designOptions?.flex?.gap?.md}
  ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Body = ({ className, children, options = cardBodyDefaultOptions }) => {
  const designOptions = { ...cardBodyDefaultOptions, ...options };
  return (
    <div
      className={`clr_v3_card__body d-flex
  row-gap-${designOptions?.rowGap?.default} 
  row-gap-md-${designOptions?.rowGap?.md}
  column-gap-${designOptions?.columnGap?.default} 
  column-gap-md-${designOptions?.columnGap?.md}
  flex-${designOptions?.flexDirection}
  ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Image = forwardRef(({ className, children, options, onClick }, ref) => {
  const designOptions = { ...cardImageDefaultOptions, ...options };
  return (
    <div
      onClick={onClick}
      className={`clr_v3_card__imageContainer  flex-shrink-0 d-flex align-items-start ${className ? className : ''
        }`}
    >
      <div
        className={`clr_v3_coverImage bg-${designOptions?.bgColor} cursorPointer position-relative overflow-hidden rounded-${designOptions?.rounded} `}
      >
        {children}
      </div>
    </div>
  );
});

ContentCard.Content = ({ className, children, options = bodyContentDefaultOptions, onClick }) => {
  const designOptions = { ...bodyContentDefaultOptions, ...options };
  return (
    <div
      onClick={onClick}
      className={`clr_v3_card__content flex-grow-1 cursorPointer shineEffect-active d-flex flex-column gap-${designOptions?.gap?.default
        } gap-md-${designOptions?.gap?.md}
  ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Title = ({
  className,
  children,
  options = contentTitleDefaultOptions,
  onClick = () => { }
}) => {
  const designOptions = { ...contentTitleDefaultOptions, ...options };
  return (
    <h4
      className={`
  fs-${designOptions?.fontSize}
  text-${designOptions?.color}
  line-clamp-${designOptions?.lineClamp}
  fw-${designOptions?.fontWeight}
  ${className ? className : ''}`}
      onClick={onClick}
    >
      {children}
    </h4>
  );
};

ContentCard.Description = ({
  className,
  children,
  options = contentDescriptionDefaultOptions,
  onClick
}) => {
  const designOptions = {
    ...contentDescriptionDefaultOptions,
    ...options
  };
  return (
    <p
      onClick={onClick}
      className={`
  fs-${designOptions?.fontSize}
  text-${designOptions?.color}
  line-clamp-${designOptions?.lineClamp}
  ${className ? className : ''}`}
    >
      {children}
    </p>
  );
};

ContentCard.Footer = ({ className, children, options }) => {
  const designOptions = { ...cardFooterDefaultOptions, ...options };
  return (
    <div
      className={`clr_v3_card__footer d-flex justify-content-between align-items-center 
      gap-${designOptions?.gap?.default} 
      gap-md-${designOptions?.gap?.md}
      flex-${designOptions?.flexDirection} ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.LeftActionBar = ({
  className,
  children,
  options = footerLeftActionBarDefaultOptions
}) => {
  const { pathname } = useLocation();
  const isDisabled =
    pathname == '/' ||
    pathname?.toLowerCase()?.includes('login') ||
    pathname?.toLowerCase()?.includes('registration') ||
    pathname?.toLowerCase()?.includes('forget-password') ||
    pathname?.toLowerCase()?.includes('reset-password') ||
    pathname?.toLowerCase()?.includes('share');
  const designOptions = {
    ...footerLeftActionBarDefaultOptions,
    ...options
  };
  return !isDisabled ? (
    <div
      className={`me-auto d-flex align-items-center
  gap-${designOptions?.gap?.default}
  gap-md-${designOptions?.gap?.md}
  ${className ? className : ''}`}
    >
      {children}
    </div>
  ) : (
    <div></div>
  );
};

ContentCard.FooterLeftIcon = ({
  className,
  children,
  options = footerLeftIconDefaultOptions,
  onClick
}) => {
  const designOptions = { ...footerLeftIconDefaultOptions, ...options };
  return (
    <div className={`d-flex align-items-center gap-2  ${className ? className : ''}`}>
      <i
        onClick={onClick}
        style={designOptions?.iconStyle || {}}
        className={`
  flaticon-${designOptions?.iconName}
  fs-${designOptions?.iconSize}
  text-${designOptions?.iconColor}
 `}
      ></i>
      <span className={`text-${designOptions?.color} fs-${designOptions?.fontSize} lh-base`}>
        {children}
      </span>
    </div>
  );
};

ContentCard.ButtonContainer = ({ className, children, options = buttonContainerDefault }) => {
  const designOptions = { ...buttonContainerDefault, ...options };
  return (
    <div
      className={`d-flex gap-3 flex-wrap
      gap-${designOptions?.gap?.default} 
      gap-md-${designOptions?.gap?.md}
      ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

ContentCard.Button = ({ className, children, options = buttonDefaultOptions, onClick }) => {
  const designOptions = {
    ...buttonDefaultOptions,
    ...options
  };

  return (
    <Button
      variant={designOptions?.variant}
      className={`d-flex flex-fill align-item-center justify-content-center
      gap-${designOptions?.gap?.default}
      gap-md-${designOptions?.gap?.md}
      ${className ? className : ''}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

ContentCard.Sponser = ({ className, sponsorLogo, options = {}, position = 'end' }) => {
  const designOptions = { ...options };
  // text-end end-0 ||
  // text-start start-0 ||
  // text-center start-50 translate-middle-y > translate-middle

  return (
    <Swiper
      className={`clr_v3_sponsor text-${position} ${position === 'start' ? 'ms-0' : position === 'end' ? 'me-0' : ''
        } ${className ? className : ''}`}
      navigation={false}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500
      }}
      effect={'fade'}
    >
      {sponsorLogo &&
        sponsorLogo?.length > 1 &&
        sponsorLogo?.map((_sp, idx) => (
          <SwiperSlide key={idx + 1} className="clr_v3_sponsor_slide w-100 position-relative">
            <LazyImage
              src={_sp}
              alt="sponsor"
              className={`mw-100 mh-100 ${position === 'center' ? 'start-50' : `${position}-0`
                } position-absolute ${position === 'center' ? 'translate-middle' : 'translate-middle-y'
                } top-50`}
              logo
            />
          </SwiperSlide>
        ))}
      {sponsorLogo &&
        sponsorLogo?.length == 1 &&
        sponsorLogo?.map((_sp, idx) => (
          <div key={idx + 1} className="clr_v3_sponsor_slide w-100 position-relative">
            <LazyImage
              src={sponsorLogo[0]}
              alt="sponsor"
              className={`mw-100 mh-100 ${position === 'center' ? 'start-50' : `${position}-0`
                } position-absolute ${position === 'center' ? 'translate-middle' : 'translate-middle-y'
                } top-50`}
              logo
            />
          </div>
        ))}
    </Swiper>
  );
};

export default ContentCard;
